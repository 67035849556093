<template>
    <section>
        <TitleBar title="İş Emri Detayları" :modi="modi" />

        <b-tabs>
            <b-tab-item class="columns is-multiline" label="Genel">
                <b-field class="column is-6 box" label="Tanım" grouped group-multiline>
                    <b-field class="column is-12" label="ID">
                        <b-input v-model="productionorder.id" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="İş Emri Açıklaması">
                        <b-input v-model="productionorder.name" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Malzeme Kodu">
                        <MaterialAutofill v-model="productionorder.material" :disabled="!!productionorder.id" />
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Durum" grouped group-multiline>
                    <b-radio-button class="column is-12" size="is-small" type="is-danger is-light" native-value="Açık" v-model="productionorder.status" :disabled="modi == 'show'" expanded>
                        Açık
                    </b-radio-button>
                    <b-radio-button class="column is-6" size="is-small" type="is-info is-light" native-value="Üretimde" v-model="productionorder.status" :disabled="modi == 'show'" expanded>
                        Üretimde
                    </b-radio-button>
                    <b-radio-button class="column is-6" size="is-small" type="is-warning is-light" native-value="Askıda" v-model="productionorder.status" :disabled="modi == 'show'" expanded>
                        Askıda
                    </b-radio-button>
                    <b-radio-button class="column is-6" size="is-small" type="is-success is-light" native-value="Kapalı" v-model="productionorder.status" :disabled="modi == 'show'" expanded>
                        Kapalı
                    </b-radio-button>
                    <b-radio-button class="column is-6" size="is-small" type="is-light" native-value="İptal" v-model="productionorder.status" :disabled="modi == 'show'" expanded>
                        İptal
                    </b-radio-button>
                </b-field>

                <b-field class="column is-6 box" label="Notlar" grouped group-multiline>
                    <b-input class="column is-12" type="textarea" v-model="productionorder.note" :rows="5" :disabled="modi == 'show'"></b-input>
                </b-field>

                <b-field class="column is-6 box" label="Üretim Planı" grouped group-multiline>
                    <b-field class="column is-12" label="Başlangıç Tarih/Saat">
                        <b-datetimepicker
                            size="is-small"
                            icon="calendar-today"
                            placeholder="Seçiniz.."
                            v-model="productionorder.planstart"
                            :timepicker="{ hourFormat: '24' }"
                            :disabled="modi == 'show'"
                            horizontal-time-picker
                            rounded
                        >
                        </b-datetimepicker>
                    </b-field>
                    <b-field class="column is-12" label="Bitiş Tarih/Saat">
                        <b-datetimepicker
                            size="is-small"
                            icon="calendar-today"
                            placeholder="Seçiniz.."
                            v-model="productionorder.planend"
                            :timepicker="{ hourFormat: '24' }"
                            :disabled="modi == 'show'"
                            horizontal-time-picker
                            rounded
                        >
                        </b-datetimepicker>
                    </b-field>
                    <b-field class="column is-12" label="Miktar (KG)">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="0.00"
                            controls-alignment="right"
                            controls-position="compact"
                            v-model="productionorder.quantity"
                            :min-step="0.001"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="İş Merkezi No/Adı">
                        <b-input v-model="productionorder.workplace" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>

                <b-field class="column is-12 box" label="Sarf Kalemleri Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" @click="bom_add" :disabled="modi == 'show'" />
                        <b-button
                            class="column ml-1"
                            type="is-info is-light"
                            icon-left="format-color-fill"
                            @click="item_fillbom"
                            label="Reçeteden Getir"
                            :disabled="modi == 'show' || !productionorder.material.id"
                        />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>

                    <b-table class="column is-12" :key="$store.state.ui.flush" :mobile-cards="false" :data="productionorder.boms" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Ürün ağacı kaydı bulunamadı.
                            </div>
                        </template>

                        <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="item.code" label="Malzeme Kodu" v-slot="props" sortable>
                            <MaterialAutofill v-model="props.row.item" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="item.name" label="Malzeme Açıklaması" v-slot="props" sortable>{{ props.row.item.name }}</b-table-column>
                        <b-table-column field="lotnum" label="Parti No" v-slot="props" sortable>
                            <LotAutofill v-model="props.row.lotnum" @value="(v) => (props.row.lotnum = v)" :id_material="props.row.item.id" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="quantity" label="Reçete Miktarı (KG)" v-slot="props" numeric sortable>
                            {{ props.row.bom_quantity }}
                        </b-table-column>
                        <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" numeric sortable>
                            <b-numberinput
                                type="is-link is-light"
                                placeholder="0.00"
                                controls-alignment="right"
                                controls-position="compact"
                                v-model="props.row.quantity"
                                :min-step="0.001"
                                :disabled="modi == 'show'"
                                expanded
                            ></b-numberinput>
                        </b-table-column>
                        <b-table-column field="action" label="Aksiyon" v-slot="props">
                            <b-button type="is-danger is-light" icon-left="delete" @click="bom_delete(props.row.id, props.index)" :disabled="modi == 'show'" />
                        </b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item class="columns is-multiline" label="Üretim Onayları" :disabled="modi == 'add'">
                <b-field class="column is-12 box" label="Üretim Onayları Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" disabled />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>

                    <b-table class="column is-12" :key="$store.state.ui.flush" :data="productionorder.confirms" :mobile-cards="false" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Üretim onayı kaydı bulunamadı.
                            </div>
                        </template>

                        <template slot="detail" slot-scope="props">
                            <tr>
                                <th></th>
                                <th></th>
                                <th>ID</th>
                                <th>Malzeme Kodu</th>
                                <th>Malzeme Adı</th>
                                <th>Parti No</th>
                                <th>Miktar (KG)</th>
                                <th></th>
                            </tr>
                            <tr v-for="item in props.row.boms" :key="item.id">
                                <td></td>
                                <td></td>
                                <td>{{ item.id.slice(-5) }}</td>
                                <td>{{ item.item.code }}</td>
                                <td>{{ item.item.name }}</td>
                                <td>{{ item.lotnum }}</td>
                                <td>{{ item.quantity }} KG</td>
                                <td></td>
                            </tr>
                        </template>

                        <b-table-column field="id" label="ID" v-slot="props">{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="lotnum" label="Üretilen Parti No" v-slot="props">{{ props.row.lotnum }}</b-table-column>
                        <b-table-column field="quantity" label="Üretilen Miktar (KG)" v-slot="props">{{ props.row.quantity }} KG</b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Kayıt Geçmişi" :disabled="!productionorder.id">
                <MetaDetails v-if="$route.query.id" />
            </b-tab-item>
        </b-tabs>

        <b-field position="is-right">
            <b-button class="ml-1" type="is-success is-light" icon-left="check" label="Kaydet" :disabled="modi == 'show'" @click="productionorder_save" />
            <b-button class="ml-1" type="is-danger is-light" icon-left="close" label="İptal" @click="productionorder_cancel" />
        </b-field>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import LotAutofill from "@/components/LotAutofill.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";
import MetaDetails from "@/components/MetaDetails.vue";

export default {
    name: "ProductionOrderDetails",
    components: {
        TitleBar,
        LotAutofill,
        MaterialAutofill,
        MetaDetails
    },
    data: () => ({
        modi: "",
        productionorder: {
            id: "",
            material: {},
            boms: [],
            confirms: []
        }
    }),
    created() {
        this.productionorder.id = this.$route.query.id || "";
        this.modi = this.$route.query.modi || "add";
        this.productionorder_read();
    },
    methods: {
        productionorder_read() {
            if (this.modi == "add") return;
            this.$apollo.queries.readProductionOrder.start();
            this.$apollo.queries.readProductionOrder.refetch();
        },
        productionorder_save() {
            var err = "";
            var payload = this.productionorder;

            delete payload.confirms;
            payload.boms.forEach((e) => delete e.bom_quantity);

            if (!payload.name) err = "İş emri açıklaması boş olamaz.";
            if (!payload.status) err = "Durum boş olamaz.";
            if (!payload.material) err = "Malzeme kodu boş olamaz.";
            if (!payload.workplace) err = "İş merkezi boş olamaz.";
            if (!payload.quantity) err = "Planlanan miktar boş olamaz.";
            if (!payload.planstart) err = "Plan başlangıcı boş olamaz.";
            if (!payload.planend) err = "Plan bitişi boş olamaz.";

            if (err)
                return this.$buefy.snackbar.open({
                    type: "is-warning",
                    message: err,
                    position: "is-bottom-right"
                });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($productionorder: String) {
                            saveProductionOrder(productionorder: $productionorder)
                        }
                    `,
                    variables: {
                        productionorder: JSON.stringify(payload)
                    }
                })
                .then(() => {
                    this.$buefy.snackbar.open({
                        type: "is-success",
                        message: "Başarılı",
                        position: "is-bottom-right"
                    });
                    return this.$router.push({ path: "/uretim/isemri/liste" });
                })
                .catch(() => {
                    return this.$buefy.snackbar.open({
                        type: "is-danger",
                        message: "Bir hata oluştu.",
                        position: "is-bottom-right"
                    });
                });
        },
        productionorder_cancel() {
            this.$router.push({ path: "/uretim/isemri/liste" });
        },
        bom_add() {
            this.productionorder.boms.push({
                id: "",
                item: {},
                lotnum: "",
                quantity: 0.0
            });
        },
        item_delete(id, index) {
            if (!id) this.productionorder.boms.splice(index, 1);
            else;
        },
        item_fillbom() {
            if (this.modi == "show") return;
            this.productionorder.boms = [];
            this.$apollo.queries.readMaterial.start();
            this.$apollo.queries.readMaterial.refetch();
        }
    },
    apollo: {
        readProductionOrder: {
            query: gql`
                query readProductionOrders($config: String) {
                    readProductionOrders(config: $config) {
                        id
                        name
                        material {
                            id
                            code
                            name
                        }
                        status
                        workplace
                        quantity
                        planstart
                        planend
                        note
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            lotnum
                            quantity
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: { id: this.productionorder.id }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readProductionOrder.stop();
                this.productionorder = data.readProductionOrders[0];
                this.productionorder.planstart = new Date(this.productionorder.planstart);
                this.productionorder.planend = new Date(this.productionorder.planend);

                if (this.modi == "ref") {
                    delete this.productionorder.id;
                    for (var bom of this.productionorder.boms) delete bom.id;
                }
            }
        },
        readMaterial: {
            query: gql`
                query readMaterials($config: String) {
                    readMaterials(config: $config) {
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            quantity
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 999,
                        sorts: [],
                        filters: { id: this.productionorder.material.id }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readMaterial.stop();

                const bom_sum = data.readMaterials[0].boms.reduce((a, c) => a + c.quantity, 0);
                data.readMaterials[0].boms.forEach((e) => {
                    this.productionorder.boms.push({
                        id: "",
                        item: {
                            id: e.item.id,
                            code: e.item.code,
                            name: e.item.name
                        },
                        lotnum: "",
                        bom_quantity: e.quantity,
                        quantity: parseFloat(((e.quantity * this.productionorder.quantity) / bom_sum).toFixed(4))
                    });
                });
            }
        }
    }
};
</script>
